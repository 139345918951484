import React, { useEffect, useContext, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { es } from 'date-fns/locale';
import { createAccessory } from "../../services/accessory";
import Context from "context/UserContext";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];
const FILE_SIZE = 8000000;

const accessorySchema = yup.object().shape({
  nombre: yup.string().required("El nombre es requerido"),
  descripcion: yup.string().required("La descripción es requerida"),
  monto: yup
    .number()
    .required("El monto es requerido")
    .positive("El monto debe ser positivo")
    .typeError("El monto debe ser un número válido")
    .test("is-decimal", "El monto debe tener máximo 2 decimales", (value) =>
      /^\d+(\.\d{1,2})?$/.test(value)
    ),
});

const schema = yup.object().shape({
  accesorios: yup.array().of(accessorySchema),
  factura: yup
    .mixed()
    .required("La factura es requerida")
    .test(
      "fileSize",
      "El archivo es muy pesado. Tamaño maximo: 8 MB.",
      (file) => {
        if (file && file.length > 0) {
          return file[0].size <= FILE_SIZE;
        } else {
          return true;
        }
      }
    )
    .test(
      "fileFormat",
      "Formato incorrecto. Favor de intentar con los siguientes formatos: pdf,png,jpg o jpeg",
      (file) => {
        if (file && file.length > 0) {
          return SUPPORTED_FORMATS.includes(file[0]?.type);
        } else {
          return true;
        }
      }
    ),

  fechaVenta: yup
    .date()
    .required("La fecha de venta es requerida")
    .typeError("La fecha de venta debe ser una fecha válida")
    .test(
      "is-current-month",
      "La fecha de venta debe estar dentro del mes actual",
      (value) => {
        const today = new Date();
        return (
          value &&
          value.getMonth() === today.getMonth() &&
          value.getFullYear() === today.getFullYear()
        );
      }
    ),
});

export default function AccessoryForm() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "accesorios",
  });
  const [factura, setFactura] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { token } = useContext(Context);

  useEffect(() => {
    append({ nombre: "", descripcion: "", monto: "" });
  }, [append]);

  const handleCloseModal = () => setShowModal(false);

  const onSubmit = async (data) => {
    setLoading(true);
    setResponseMessage("");
    try {
      if (!factura) {
        setError("factura", {
          type: "manual",
          message: "La factura es requerida",
        });
        throw new Error("La factura es requerida");
      }
      await createAccessory({ ...data, factura }, token);
      setResponseMessage("Accesorios guardados exitosamente");
      reset({
        accesorios: [{ nombre: "", descripcion: "", monto: "" }],
        fechaVenta: "",
        factura: null,
      });
      setFactura(null);
    } catch (error) {
      setResponseMessage("Error al guardar los accesorios");
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setShowModal(true);
    }
  };


  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Card style={{ width: "100%", maxWidth: "800px" }}>
        <Card.Body>
          <Card.Title className="text-center mb-4">
            Formulario de Accesorios
          </Card.Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => (
              <Row key={item.id} className="mb-3">
                <Col md={4}>
                  <Form.Group controlId={`accesorios[${index}].nombre`}>
                    <Form.Label>Nombre del Accesorio</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre"
                      {...register(`accesorios[${index}].nombre`, {
                        required: "El nombre es requerido",
                      })}
                      isInvalid={!!errors.accesorios?.[index]?.nombre}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.accesorios?.[index]?.nombre?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`accesorios[${index}].descripcion`}>
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Descripción"
                      {...register(`accesorios[${index}].descripcion`, {
                        required: "La descripción es requerida",
                      })}
                      isInvalid={!!errors.accesorios?.[index]?.descripcion}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.accesorios?.[index]?.descripcion?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`accesorios[${index}].monto`}>
                    <Form.Label>Monto</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Monto"
                      {...register(`accesorios[${index}].monto`, {
                        required: "El monto es requerido",
                        valueAsNumber: true,
                      })}
                      isInvalid={!!errors.accesorios?.[index]?.monto}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.accesorios?.[index]?.monto?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {index > 0 && (
                  <Col md={12} className="text-end">
                    <Button variant="danger" onClick={() => remove(index)}>
                      Eliminar
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
            <Row className="mb-3">
              <Col md={12} className="text-center">
                <Button
                  variant="primary"
                  onClick={() =>
                    append({ nombre: "", descripcion: "", monto: "" })
                  }
                >
                  Agregar más accesorios
                </Button>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="fechaVenta">
                  <Form.Label>Fecha de Venta</Form.Label>


                  <Controller
                    control={control}
                    name="fechaVenta"
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Selecciona la fecha de venta"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        dateFormat="dd/MM/yyyy"
                        minDate={startOfMonth}
                        maxDate={endOfMonth}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className={`form-control ${errors.fechaVenta ? 'is-invalid' : ''}`}
                        disabledKeyboardNavigation
                        locale={es}
                      />
                    )}
                  />


                  <Form.Control.Feedback type="invalid">
                    {errors.fechaVenta?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="factura">
                  <Form.Label>Factura</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    {...register("factura", {
                      required: "La factura es requerida",
                      onChange: (e) => {
                        const file = e.target.files[0];
                        setFactura(file ? [file] : null);
                      },
                    })}
                    isInvalid={!!errors.factura}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.factura?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12} className="text-center">
                <Button type="submit" variant="success" disabled={loading}>
                  {loading ? "Enviando..." : "Enviar"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Mensaje de Respuesta</Modal.Title>
        </Modal.Header>
        <Modal.Body>{responseMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
