const ENDPOINT = process.env.REACT_APP_API_URL;

export async function createAccessory(data, token) {
  const formData = new FormData();
  formData.append('factura', data.factura[0]);

  const fechaVenta = new Date(data.fechaVenta);
  const formattedDate = `${fechaVenta.getDate().toString().padStart(2, '0')}/${(fechaVenta.getMonth() + 1).toString().padStart(2, '0')}/${fechaVenta.getFullYear()}`;
  formData.append('fecha_venta', formattedDate);

  data.accesorios.forEach((accesorio, index) => {
    formData.append(`accesorios[${index}][nombre]`, accesorio.nombre);
    formData.append(`accesorios[${index}][descripcion]`, accesorio.descripcion);
    formData.append(`accesorios[${index}][monto]`, accesorio.monto);
  });

  const response = await fetch(`${ENDPOINT}/accessories`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token, 
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Error en la petición');
  }

  return response.json();
}