import React, { useState, useEffect } from "react";
import useDashboard from "../../hooks/useDashboard";
import useUser from "../../hooks/useUser";
import toast, { Toaster } from "react-hot-toast";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { filterBy } from "@progress/kendo-data-query";

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  {
    field: "folio",
    dir: "desc",
  },
];

export default function VentasPromotor() {
  const { success, message, Ventas, VentasAddService } = useDashboard();
  const { tokenFull, IdUser, Roles } = useUser();
  const [Desde, setDesde] = useState("");
  const [Hasta, setHasta] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);

  const ProductosAgregados = () => {
    toast.loading("Registrando...");
    const data = new FormData();
    data.append("rol", Roles);
    data.append("user_id", IdUser);
    data.append("desde", Desde);
    data.append("hasta", Hasta);

    VentasAddService(data, tokenFull);
  };

  useEffect(() => {
    if (success) {
      toast.dismiss();
      toast.success(message);
    }
  }, [message, success]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const fecha = (fecha) => {
    let meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let a = fecha.substr(0, 4);
    let m = fecha.substr(5, 2);
    let d = fecha.substr(8);
    return d + " de " + meses[m - 1] + " de " + a;
  };

  return (
    <>
      <Toaster />
      <h2 className="titulo2">Ventas</h2>
      <div className="row">
        <div className="col-md-4">
          <label>Desde</label>
          <input
            type="date"
            className="form-control"
            value={Desde}
            onChange={(e) => setDesde(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label>Hasta</label>
          <input
            type="date"
            className="form-control"
            value={Hasta}
            onChange={(e) => setHasta(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => ProductosAgregados()}
            style={{ marginTop: 20 }}
          >
            Buscar
          </button>
        </div>
      </div>
      <br />

      <div className="row">
        <div className="col-md-12">
          <Grid
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            filterable={true}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}
            pageable={true}
            data={filterBy(
              Ventas.slice(page.skip, page.take + page.skip),
              filter
            )}
            skip={page.skip}
            take={page.take}
            total={Ventas.length}
            onPageChange={pageChange}
            style={{
              height: "420px",
            }}
          >
            <GridNoRecords>No hay informacion disponible</GridNoRecords>
            <GridColumn field="modelo" title="Modelo" />
            <GridColumn field="serie" title="No. Serie" />
            <GridColumn
              field="Fecha"
              filter="text"
              cell={(a) => {
                let fecha_venta = a.dataItem.Fecha;
                return (
                  <td style={{ marginTop: 10, textAlign: "center" }}>
                    {fecha(fecha_venta)}
                  </td>
                );
              }}
            />
            <GridColumn
              field="Estatus de la venta"
              filter="text"
              format={null}
              cell={(e) => {
                let status = e.dataItem.status;
                return (
                  <td style={{ marginTop: 10, textAlign: "right" }}>
                    {status === "validado" ? (
                      <span className="badge bg-success">Validado</span>
                    ) : status === "no validado" ? (
                      <span className="badge bg-danger">No validado</span>
                    ) : status === "en espera" ? (
                      <span className="badge bg-warning">En espera</span>
                    ) : (
                      "No definido"
                    )}
                  </td>
                );
              }}
            />
          </Grid>
        </div>
      </div>
    </>
  );
}
